import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import toast from 'react-hot-toast';

export const fetchAll = createAsyncThunk(
    'template/fetchAll',
    async (params) => {
        const response = await api.post("/api/template/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'template/fetchById',
    async (id) => {
        const response = await api.get(`/api/template/${id}`)
        return response.data;
    }
);

export const fetchContentById = createAsyncThunk(
    'template/fetchContentById',
    async (id) => {
        const response = await api.get(`/api/template/${id}/content`)
        return response.data;
    }
);

export const updateContent = createAsyncThunk(
    'template/updateContent',
    async (data) => {
        debugger
        const response = await api.put(`/api/template/${data.id}/content`,data)
        return response.data;
    }
);


export const sendSample = createAsyncThunk(
    'template/sendSample',
    async (data, thunkApi) => {
        try {
            const response = await api.post(`/api/template/${data.id}/send`, data)
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);   
        }
    }
);

export const create = createAsyncThunk(
    'template/create',
    async ({data}, thunkApi) => {
        try {
            const response = await api.post(`/api/template/create`, data)
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);   
        }
    }
);

export const update = createAsyncThunk(
    'template/update',
    async ({data} , { rejectWithValue }) => {
       try {
        const response = await api.post(`/api/template`, data)
        return response.data;
       } catch (err) {        
           return rejectWithValue(err.response.data);
       }
    }
);

export const parseTemplate = createAsyncThunk(
    'emailtemplate/parser',
    async ( data , { rejectWithValue }) => {
        try {
            const response = await api.post(`/api/template/parse`, data);
            return response.data;
        } catch (err) {
            console.error(err)
            return rejectWithValue(err.response.data)
        }
    }
);
export const editable = createAsyncThunk(
    'emailtemplate/editable',
    async ({ id, ...data }) => {
        const response = await api.put(`/api/template/${id}/editable`, data)
        return response.data;
    }
);
export const changeStatus = createAsyncThunk(
    'emailtemplate/changeStatus',
    async ({ id, ...data }) => {
        const response = await api.put(`/api/template/${id}/status`, data)
        return response.data;
    }
);


const templateSlice = createSlice({
    name: 'template',
    initialState: {
        items: [],
        loading: false,
        saving: false,
        error: null,
        errors: null,
        parsedHtml: '<h5 class="text-primary fw-bold">Rendering...</h5>',
        selectedItem: {},
        templateContent:{},
        isCreateModalOpen: false,
        isSendTestEmailModalOpen: false,
        sendingTestEmail: false,
    },
    reducers: {
        // Other reducer actions...
        openCreateModal: (state, action) => {
            state.isCreateModalOpen = action.payload;
        },
        openSendTestEmailModal: (state, action) => {
            state.isSendTestEmailModalOpen = action.payload;
        },
        setParsedHtml: (state, action) => {
            state.parsedHtml = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(create.fulfilled, (state, action) => {
                state.items.push(action.payload);
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload);
                state.isCreateModalOpen = false;
            })
            .addCase(create.rejected, (state, action) => {
                const { errorCallback } = action.meta.arg;
                errorCallback && errorCallback(action.payload.errors)
            })
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                state.parsedHtml = action.payload.parsedHtml;
            })
            .addCase(sendSample.pending, (state, action) => {
                state.sendingTestEmail = true;
            })
            .addCase(sendSample.fulfilled, (state, action) => {
                state.sendingTestEmail = false;
                state.isSendTestEmailModalOpen = false;
                toast.success("Template sample has been sent successfully", {
                    duration: 3000,
                })
            })
            .addCase(sendSample.rejected, (state, action) => {
                state.sendingTestEmail = false;
                toast.error("failed to send test email", {
                    duration: 3000,
                  })
            })
            .addCase(updateContent.pending, (state, action) => {
                state.saving = true;
                state.templateContent = {};
            })
            .addCase(updateContent.fulfilled, (state, action) => {
                state.saving = false;
                state.templateContent = action.payload;
                toast.success("template content updated successfully", {
                    duration: 3000,
                  })
            })
            .addCase(updateContent.rejected, (state, action) => {
                state.saving = false;
                state.templateContent = {};
                toast.error("failed to update template content", {
                    duration: 3000,
                  })
            })
            .addCase(fetchContentById.pending, (state, action) => {
                state.loading = true;
             
            })
            .addCase(fetchContentById.fulfilled, (state, action) => {
                state.loading = false;
                state.templateContent = action.payload;
            })
            .addCase(fetchContentById.rejected, (state, action) => {
                state.loading = false;
                state.templateContent = {};
            })
            .addCase(parseTemplate.pending, (state, action) => {
                state.loading = true;
                state.parsedHtml = '<h5 class="text-primary fw-bold">Rendering...</h5>';
            })
            .addCase(parseTemplate.fulfilled, (state, action) => {
                state.loading = false;
                state.parsedHtml = action.payload;
            })
            .addCase(parseTemplate.rejected, (state, action) => {
                state.loading = false
                const {payload}=action
                state.parsedHtml = `<h3 class='error-message'> <strong>Failed to parse the template content with the status code: ${payload.statusCode}</strong> </h3> <br> <h4>${payload.errorMessage}</h4>`;
            })
            .addCase(editable.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(editable.fulfilled, (state, action) => {
                const { id, isEditable } = action.payload;
                const index = state.items.findIndex(x => x.id == id);
                state.loading = false;
                if (state.items[index]) {
                    state.items[index].isEditable = isEditable;
                }
                if (state.selectedItem.id === id) {
                    state.selectedItem.isEditable = isEditable;
                }
            })
            .addCase(changeStatus.fulfilled, (state, action) => {
                const { id, status } = action.payload;
                const index = state.items.findIndex(x => x.id == id);
                if (state.items[index]) {
                    state.items[index].status = status;
                }
                if (state.selectedItem.id === id) {
                    state.selectedItem.status = status;
                }
            })

    },
});


export const { openCreateModal, openSendTestEmailModal, setParsedHtml } = templateSlice.actions;

export default templateSlice.reducer;

